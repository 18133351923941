var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, persistent: "", width: "500" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "#1C3144" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(" " + _vm._s(_vm.uploadType.name) + " Upload ")
              ])
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-7" },
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _vm.uploadType.directory === "Recap_Additions"
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  label: "Recap Type",
                                  outlined: "",
                                  dense: "",
                                  "background-color": "white",
                                  items: _vm.recapAdditionTypes,
                                  "item-text": "name",
                                  "item-value": "comp_type",
                                  clearable: "",
                                  rules: [
                                    function(v) {
                                      return !!v || "Recap Type is Required"
                                    }
                                  ]
                                },
                                on: {
                                  change: function($event) {
                                    _vm.comment = ""
                                  }
                                },
                                model: {
                                  value: _vm.recapType,
                                  callback: function($$v) {
                                    _vm.recapType = $$v
                                  },
                                  expression: "recapType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-file-input", {
                            attrs: {
                              outlined: "",
                              dense: "",
                              "background-color": "#fff",
                              "prepend-inner-icon": "mdi-paperclip",
                              "prepend-icon": "",
                              accept:
                                ".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                              chips: "",
                              label: "Select File for Upload",
                              "truncate-length": "50",
                              "show-size": "",
                              rules: [
                                function(v) {
                                  return !!v || "File is Required"
                                }
                              ]
                            },
                            model: {
                              value: _vm.file,
                              callback: function($$v) {
                                _vm.file = $$v
                              },
                              expression: "file"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.uploadType.info_required
                    ? _c(
                        "section",
                        { staticClass: "pb-6" },
                        [
                          _vm.uploadType.directory === "Recap_Additions"
                            ? _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          "background-color": "#fff",
                                          label: "Year",
                                          items: _vm.years,
                                          rules: [
                                            function(v) {
                                              return !!v || "Year is Required"
                                            }
                                          ],
                                          "menu-props": { maxHeight: 175 }
                                        },
                                        model: {
                                          value: _vm.year,
                                          callback: function($$v) {
                                            _vm.year = $$v
                                          },
                                          expression: "year"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          label: "Period",
                                          items: _vm.periods,
                                          outlined: "",
                                          dense: "",
                                          "background-color": "white",
                                          rules: [
                                            function(v) {
                                              return !!v || "Period is Required"
                                            }
                                          ],
                                          "menu-props": { maxHeight: 175 }
                                        },
                                        model: {
                                          value: _vm.period,
                                          callback: function($$v) {
                                            _vm.period = $$v
                                          },
                                          expression: "period"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.uploadType.directory === "Manual_Invoice"
                            ? _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          "background-color": "#fff",
                                          label: "Manufacturer",
                                          items: _vm.manufacturers,
                                          rules: [
                                            function(v) {
                                              return (
                                                !!v ||
                                                "Manufacturer is Required"
                                              )
                                            }
                                          ],
                                          "menu-props": { maxHeight: 175 },
                                          "item-value": "id",
                                          "item-text": "description"
                                        },
                                        model: {
                                          value: _vm.subVendorId,
                                          callback: function($$v) {
                                            _vm.subVendorId = $$v
                                          },
                                          expression: "subVendorId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.uploadType.directory === "Manual_Invoice"
                            ? _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          "background-color": "#fff",
                                          label: "Add Deduction Fee"
                                        },
                                        model: {
                                          value: _vm.addDeductionFee,
                                          callback: function($$v) {
                                            _vm.addDeductionFee = $$v
                                          },
                                          expression: "addDeductionFee"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          "background-color": "#fff",
                                          label: "Deduction Fee",
                                          disabled: !_vm.addDeductionFee,
                                          placeholder: "5.00",
                                          min: "0",
                                          type: "number",
                                          step: "0.01",
                                          prefix: "$",
                                          autocomplete: "off"
                                        },
                                        on: { change: _vm.formatFee },
                                        model: {
                                          value: _vm.deductionFee,
                                          callback: function($$v) {
                                            _vm.deductionFee = _vm._n($$v)
                                          },
                                          expression: "deductionFee"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  "background-color": "white",
                                  "no-resize": "",
                                  label: "Comments",
                                  rules:
                                    _vm.recapType === "Misc_Credits"
                                      ? [
                                          function(v) {
                                            return !!v || "Comment is Required"
                                          }
                                        ]
                                      : [],
                                  rows: "3"
                                },
                                model: {
                                  value: _vm.comment,
                                  callback: function($$v) {
                                    _vm.comment = $$v
                                  },
                                  expression: "comment"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "#fafafa" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    loading: _vm.loading,
                    disabled: _vm.uploadType.info_required
                      ? !_vm.required
                      : !_vm.file,
                    color: "#00B24A"
                  },
                  on: { click: _vm.uploadFile }
                },
                [
                  _vm._v(" Upload"),
                  _c("v-icon", { attrs: { right: "" } }, [
                    _vm._v("mdi-file-upload-outline")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }