<template>
  <v-dialog :value="value" persistent width="500">
    <v-card>
      <v-toolbar dark flat color="#1C3144">
        <v-btn icon @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{uploadType.name}} Upload
        </v-toolbar-title>
      </v-toolbar>
      <v-container class="ma-0 pa-7">
        <v-form ref="form">
          <v-row v-if="uploadType.directory === 'Recap_Additions'" dense>
            <v-col>
              <v-autocomplete label="Recap Type" outlined dense background-color="white" :items="recapAdditionTypes"
                item-text="name" item-value="comp_type" v-model="recapType" clearable
                :rules="[v => !!v || 'Recap Type is Required']" @change="comment=''" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-file-input outlined dense background-color="#fff" v-model="file" prepend-inner-icon="mdi-paperclip"
                prepend-icon="" accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                chips label="Select File for Upload" truncate-length="50" show-size
                :rules="[v => !!v || 'File is Required']">
              </v-file-input>
            </v-col>
          </v-row>
          <section v-if="uploadType.info_required" class="pb-6">
            <v-row v-if="uploadType.directory === 'Recap_Additions'" dense>
              <v-col>
                <v-autocomplete outlined dense background-color="#fff" label="Year" :items="years"
                  :rules="[v => !!v || 'Year is Required']" :menu-props="{ maxHeight: 175 }" v-model="year" />
              </v-col>
              <v-col>
                <v-autocomplete label="Period" :items="periods" outlined dense background-color="white"
                  :rules="[v => !!v || 'Period is Required']" :menu-props="{ maxHeight: 175 }" v-model="period" />
              </v-col>
            </v-row>
            <v-row v-if="uploadType.directory === 'Manual_Invoice'" dense>
              <v-col>
                <v-autocomplete outlined dense background-color="#fff" label="Manufacturer" :items="manufacturers"
                  :rules="[v => !!v || 'Manufacturer is Required']" :menu-props="{ maxHeight: 175 }" v-model="subVendorId" 
                  item-value="id" item-text="description"/>
              </v-col>
            </v-row>
            <v-row v-if="uploadType.directory === 'Manual_Invoice'" dense>
              <v-col>
                <v-checkbox outlined dense background-color="#fff" label="Add Deduction Fee" v-model="addDeductionFee" />
              </v-col>
              <v-col>
                <v-text-field outlined dense background-color="#fff" label="Deduction Fee" v-model.number="deductionFee"
                  :disabled="!addDeductionFee" placeholder="5.00" min="0" type="number" step="0.01" prefix="$" autocomplete="off" @change="formatFee"/>
              </v-col>
            </v-row>
            <v-row dense>
              <v-textarea outlined dense background-color="white" no-resize label="Comments" v-model="comment"
                :rules="recapType === 'Misc_Credits' ? [v => !!v || 'Comment is Required'] : []" rows="3" />
            </v-row>
          </section>
        </v-form>
      </v-container>
      <v-divider />
      <v-toolbar flat color="#fafafa">
        <v-spacer />
        <v-btn :loading="loading" :disabled="uploadType.info_required ? !required : !file" color="#00B24A"
          class="white--text" @click="uploadFile">
          Upload<v-icon right>mdi-file-upload-outline</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { notification } from '@/mixins/notification'
import { Storage } from 'aws-amplify'
import { mapState } from 'vuex'

export default {
  name: 'FileUploader',

  mixins: [notification],

  created() {
    this.getManufacturers()
  },
  props: ['value', 'uploadType'],

  data () {
    return {
      file: null,
      year: null,
      period: null,
      recapType: '',
      comment: '',
      subVendorId: null,
      manufacturers: [],
      addDeductionFee: true,
      deductionFee: (5.00 * 1).toFixed(2),
      loading: false
    }
  },

  computed: {
    ...mapState(['user']),

    recapAdditionTypes () {
      return [
        { name: 'Meat - Miscellaneous', directory: 'Meat_Miscellaneous', comp_type: 'Meat - Miscellaneous' },
        { name: 'Grocery - Miscellaneous', directory: 'Grocery_Miscellaneous', comp_type: 'Grocery - Miscellaneous' },
        { name: 'Dairy - Miscellaneous', directory: 'Dairy_Miscellaneous', comp_type: 'Dairy - Miscellaneous' },
        { name: 'Frozen - Miscellaneous', directory: 'Frozen_Miscellaneous', comp_type: 'Frozen - Miscellaneous' },
        { name: 'Deli/Bakery - Miscellaneous', directory: 'Deli_Miscellaneous', comp_type: 'Deli/Bakery - Miscellaneous' },
        { name: 'Produce - Miscellaneous', directory: 'Produce_Miscellaneous', comp_type: 'Produce - Miscellaneous' },
        { name: 'Misc Credits', directory: 'Misc_Credits', comp_type: 'Miscellaneous' },
        { name: 'New Items', directory: 'New_Items', comp_type: 'New Items' },
        { name: 'Period Ad Fees', directory: 'Period_Ad_Fees', comp_type: 'Period Ad Fees' },
        { name: 'VMC', directory: 'VMC', comp_type: 'VMC Ad Fee' }
      ]
    },

    years () {
      const years = []
      const dateStart = this.moment().subtract(3, 'y')
      const dateEnd = this.moment().add(3, 'y')
      while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push(dateStart.format('YYYY'))
        dateStart.add(1, 'year')
      }
      return years
    },

    periods () {
      return this._.range(1, 14)
    },

    required () {
      const requiredFields = [
        Boolean(this.file),
        this.uploadType.directory === 'Recap_Additions' ? Boolean(this.year) : true,
        this.uploadType.directory === 'Recap_Additions' ? Boolean(this.period) : true,
        this.uploadType.directory === 'Recap_Additions' ? Boolean(this.recapType) : true,
        this.uploadType.directory === 'Manual_Invoice' ? Boolean(this.subVendorId) : true,
      ]

      if (this.recapType === 'Misc_Credits') {
        requiredFields.push(Boolean(this.comment))
      }

      return requiredFields.every(field => field)
    }
  },

  methods: {
    async uploadFile () {
      this.loading = true
      Storage.configure({
        AWSS3: {
          bucket: process.env.VUE_APP_FILE_UPLOAD_BUCKET,
          region: 'us-east-1'
        }
      })

      const file = this.file.name.replace(/ /g, '_')
      const filePath = `${this.uploadType.directory}/${file}`

      try {
        await Storage.put(filePath, this.file, {
          metadata: {
            user: this.user.username,
            year: this.year ? this.year : '',
            period: this.period ? (this.period).toString() : '',
            recap_type: this.recapType ? this.recapType : '',
            comment: this.comment ? this.comment : '',
            sub_vendor_id: this.subVendorId ? this.subVendorId.toString() : '',
            deduction_fee: this.addDeductionFee ? (this.deductionFee).toString() : '0'
          }
        })
        this.notify('success', 'File Successfully Submitted')
      } catch {
        this.notify('error', 'Upload Attempt Failed')
      } finally {
        this.loading = false
        this.$emit('update:value', false)
      }
    },
    async getManufacturers () {
      await this.$uploads.getManufacturers().then(response=> {
        this.manufacturers = response
      })
    },
    formatFee (value) {
      if (!value) return ''
      this.deductionFee = ((value * 100) / 100).toFixed(2)
    }
  }
}
</script>
